class SessionStorageManager {
  static prefix = 'PORTAL_SESSION_';

  static setItem(key, data) {
    sessionStorage.setItem(`${this.prefix}${key}`, JSON.stringify(data));
  }

  static removeItem(key) {
    if (typeof sessionStorage[`${this.prefix}${key}`] !== 'undefined') {
      sessionStorage.removeItem(`${this.prefix}${key}`);
    }
  }

  static getItem(key) {
    if (
      typeof sessionStorage[`${this.prefix}${key}`] !== 'undefined' &&
      sessionStorage[`${this.prefix}${key}`] !== 'undefined'
    ) {
      const item = sessionStorage.getItem(`${this.prefix}${key}`);

      try {
        const parsedItem = JSON.parse(item);

        return parsedItem;
      } catch (error) {
        return item;
      }
    }
    return undefined;
  }
}

export default SessionStorageManager;
