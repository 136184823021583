import SessionStorageManager from './SessionStorageManager';
import LocalStorageManager from './LocalStorageManager';
import history from '../config/history';

const authPath = /\/auth/i;

const applyInterceptors = (gateway) => {
  // Session interceptor
  gateway.interceptors.request.use((config) => {
    const newConfig = config;
    const isAuth = /\/auth\/refresh/i.test(config.url);

    if (!isAuth) {
      const token = LocalStorageManager.getToken();

      if (token) {
        newConfig.headers['x-access-token'] = token;
      }
    }

    return newConfig;
  });

  gateway.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (authPath.test(originalRequest.url)) {
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest.retried) {
        const refreshToken = LocalStorageManager.getRefreshToken();
        originalRequest.retried = true;
        return gateway
          .post('/auth/refresh', null, {
            headers: { 'x-refresh-token': refreshToken },
          })
          .then((res) => {
            if (res.status === 200) {
              LocalStorageManager.setToken(res.headers['x-access-token']);
              return gateway(originalRequest);
            }
          })
          .catch(async (err) => {
            if (err.response && err.response.status === 401) {
              LocalStorageManager.removeRefreshToken();
              LocalStorageManager.removeToken();
              SessionStorageManager.clear();
              history.push('/login', {
                errorMsg: 'Sessão expirada',
                redirectTo: window.location.href,
              });
            }
          });
      }

      // return Error object with Promise
      return Promise.reject(error);
    },
  );
};

export default applyInterceptors;
