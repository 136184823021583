import axios from 'axios';
import qs from 'qs';

import { CURRENT_ENV } from '../constants/constants';

const hosts = {
  development: `http://localhost:3002/api/v1`,
  staging: 'https://staging-gateway.eyemobile.com.br/api/v1',
  production: 'https://gateway.eyemobile.com.br/api/v1',
};

export const currentHost = hosts[CURRENT_ENV];

const instance = axios.create({
  baseURL: currentHost,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 60000,
  paramsSerializer: (params) => {
    return qs.stringify(params);
  },
});

export default instance;
