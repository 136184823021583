import React, { useRef, useState, useCallback } from 'react';
import { css, keyframes } from '@emotion/core';
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/styles';
import facepaint from 'facepaint';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { compose, bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import gateway from '../utils/gateway';
import LocalStorageManager from '../utils/LocalStorageManager';
import { currentHost } from '../utils/http';
import FormikField from './FormikInput';
import { ReactComponent as Logo } from '../assets/eyemobile_logo_white.svg';
import { getUser } from '../store/modules/user';
import { AuthError, InvalidAccessError } from '../utils/errors';

const mq = facepaint(['@media screen and (min-width: 360px)']);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUser: getUser,
    },
    dispatch,
  );

const shake = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(20px);
  }
  50% {
    transform: translateX(-20px);
  }
  75% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
`;

const fadeOutTop = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(-150px);
  }
`;

const shakeAnimation = css`
  animation: ${shake} 0.5s ease 0s 1;
`;

const fadeOutTopAnimation = css`
  animation: ${fadeOutTop} 0.8s ease 0s 1;
`;

const loginSchema = Yup.object().shape({
  username: Yup.string().required('common:fieldRequired'),
  password: Yup.string().required('common:fieldRequired'),
});

const pageStyles = () => ({
  button: {
    backgroundColor: '#1E92C6',
    borderRadius: 30,
    color: '#FAFAFA',
    padding: '14px 0',
    lineHeight: 1,
    fontWeight: '600',
    fontFamily: 'Avenir-Medium',
    '&:hover': {
      backgroundColor: '#1E92C6',
      color: '#FAFAFA',
    },
    '&:focus': {
      color: '#FAFAFA',
    },
    '&:disabled': {
      backgroundColor: '#dddddd',
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  progressBar: {
    backgroundColor: '#999',
  },
  progressRoot: {
    backgroundColor: 'transparent',
  },
});

const boxWrapper = css(
  mq({
    width: '100%',
    padding: ['32px 16px', '45px 62px'],
    maxWidth: 403,
    position: 'relative',
  }),
);

const LoginPage = ({ classes, history, location, fetchUser }) => {
  const { t } = useTranslation();

  const loadingTimer = useRef(false);
  const [showPassword, setShowPassword] = useState(false);
  const [completed, setCompleted] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShake, setShouldShake] = useState(false);
  const [shouldFadeTop, setShouldFadeTop] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const progress = () => {
    setCompleted((oldCompleted) => {
      const remain = 100 - oldCompleted;
      const distance = Math.floor(remain / 8);
      const max = oldCompleted + distance;
      return Math.floor(
        Math.random() * (max - oldCompleted + 1) + oldCompleted,
      );
    });
  };

  const startProgress = () => {
    clearInterval(loadingTimer);
    setCompleted(0);
    setIsLoading(true);
    const intervalId = setInterval(progress, 500);
    loadingTimer.current = intervalId;
  };

  const finishProgress = () => {
    clearInterval(loadingTimer.current);
    setCompleted(100);
    setIsLoading(false);
  };

  const handleLogin = useCallback(async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('yourAction');
    // Do whatever you want with the token

    try {
      setShouldShake(false);
      setShouldFadeTop(false);
      setHasError(false);
      startProgress();
      history.replace('/login', {});

      const gatewayResponse = await gateway
        .post('/auth/login', {
          username: values.username,
          password: values.password,
          token,
        })
        .catch((e) => Promise.reject(new AuthError(e.message)));

      LocalStorageManager.setToken(gatewayResponse.headers['x-access-token']);
      LocalStorageManager.setRefreshToken(
        gatewayResponse.headers['x-refresh-token'],
      );

      await fetchUser();

      finishProgress();
      setShouldFadeTop(true);

      setTimeout(() => {
        if (location.state && location.state.redirectTo) {
          window.location.assign(location.state.redirectTo);
        } else {
          history.push('/tenants');
        }
      }, 700);
    } catch (e) {
      finishProgress();
      if (e instanceof AuthError) {
        setHasError(true);
      }
      if (!(e instanceof InvalidAccessError)) {
        setShouldShake(true);
      }
      console.error(e);
    }
  });

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: '#1E92C6',
        padding: '0 16px',
        flexDirection: 'column',
      }}
    >
      <Logo
        css={shouldFadeTop && fadeOutTopAnimation}
        style={{
          marginBottom: '52.6px',
          display: 'block',
          width: 210,
          height: 30,
        }}
      />
      <Paper
        css={[
          boxWrapper,
          shouldShake && shakeAnimation,
          shouldFadeTop && fadeOutTopAnimation,
        ]}
        elevation={4}
        style={{
          borderRadius: 16,
          overflow: 'hidden',
        }}
      >
        <LinearProgress
          variant="determinate"
          value={completed}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            opacity: isLoading ? 1 : 0,
            transition: 'opacity 0.2s',
          }}
          classes={{
            root: classes.progressRoot,
            bar: classes.progressBar,
          }}
        />
        {location.state && location.state.errorMsg && (
          <p
            style={{
              textAlign: 'center',
              color: 'rgb(255, 79, 101)',
            }}
          >
            {location.state.errorMsg}{' '}
            {location.state.spaUrl && (
              <a
                style={{ textDecoration: 'underline' }}
                href={location.state.spaUrl}
              >
                {t('common:accessCloudHere')}
              </a>
            )}
          </p>
        )}
        {hasError && (
          <p
            style={{
              textAlign: 'center',
              color: 'rgb(255, 79, 101)',
            }}
          >
            {t('common:invalidLogin')}
          </p>
        )}
        <Formik
          validationSchema={loginSchema}
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={handleLogin}
        >
          {({ handleSubmit, isValid }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  marginBottom: 40,
                }}
              >
                <Field
                  name="username"
                  component={FormikField}
                  placeholder={t('common:user')}
                  className="input-default"
                  hideIcons
                  errorStyle={{
                    position: 'absolute',
                    top: '100%',
                    right: 0,
                  }}
                  containerClassName="input-container-md"
                  inputIcon={
                    <i
                      className="ic-blue-profile mr"
                      style={{ flexShrink: 0 }}
                    />
                  }
                />
              </div>
              <div
                style={{
                  position: 'relative',
                  marginBottom: 30,
                }}
              >
                <Field
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  component={FormikField}
                  placeholder={t('common:password')}
                  className="input-default"
                  hideIcons
                  errorStyle={{
                    position: 'absolute',
                    top: '100%',
                    right: 0,
                  }}
                  containerClassName="input-container-md"
                  inputIcon={<i className="ic-blue-password mr" />}
                />
                <IconButton
                  edge="end"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  style={{
                    position: 'absolute',
                    top: -6,
                    right: 10,
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>
              <Button
                classes={{
                  root: classes.button,
                }}
                type="submit"
                disabled={isLoading || !isValid}
                variant="contained"
                color="primary"
              >
                {t('common:log_in')}
              </Button>
              <p
                style={{
                  fontSize: 15,
                  lineHeight: 1.33,
                  color: '#4a4a4a',
                  textAlign: 'center',
                  marginTop: '37.2px',
                  marginBottom: 0,
                }}
              >
                {`${t('common:forgot_your_password')} `}
                <a
                  href={`${currentHost}/login/password-recovery`}
                  style={{ fontWeight: 900 }}
                >
                  {t('common:recover_here')}
                </a>
              </p>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withStyles(pageStyles),
);

export default enhance(LoginPage);
