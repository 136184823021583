class LocalStorageManager {
  static prefix = 'PORTAL_SESSION_';

  static setItem(key, data) {
    localStorage.setItem(`${this.prefix}${key}`, JSON.stringify(data));
  }

  static removeItem(key) {
    if (typeof localStorage[`${this.prefix}${key}`] !== 'undefined') {
      localStorage.removeItem(`${this.prefix}${key}`);
    }
  }

  static getItem(key) {
    if (
      typeof localStorage[`${this.prefix}${key}`] !== 'undefined' &&
      localStorage[`${this.prefix}${key}`] !== 'undefined'
    ) {
      const item = localStorage.getItem(`${this.prefix}${key}`);

      try {
        const parsedItem = JSON.parse(item);

        return parsedItem;
      } catch (error) {
        return item;
      }
    }
    return undefined;
  }

  static setToken(token) {
    localStorage.setItem(`${this.prefix}TOKEN`, token);
  }

  static getToken() {
    if (
      typeof localStorage[`${this.prefix}TOKEN`] !== 'undefined' &&
      localStorage[`${this.prefix}TOKEN`] !== 'undefined'
    ) {
      return localStorage.getItem(`${this.prefix}TOKEN`);
    }
    return undefined;
  }

  static removeToken() {
    if (typeof localStorage[`${this.prefix}TOKEN`] !== 'undefined') {
      localStorage.removeItem(`${this.prefix}TOKEN`);
    }
  }

  static setRefreshToken(token) {
    localStorage.setItem(`${this.prefix}REFRESH_TOKEN`, token);
  }

  static getRefreshToken() {
    if (
      typeof localStorage[`${this.prefix}REFRESH_TOKEN`] !== 'undefined' &&
      localStorage[`${this.prefix}REFRESH_TOKEN`] !== 'undefined'
    ) {
      return localStorage.getItem(`${this.prefix}REFRESH_TOKEN`);
    }
    return undefined;
  }

  static removeRefreshToken() {
    if (typeof localStorage[`${this.prefix}REFRESH_TOKEN`] !== 'undefined') {
      localStorage.removeItem(`${this.prefix}REFRESH_TOKEN`);
    }
  }
}

export default LocalStorageManager;
