export default {
  user: 'Usuário',
  password: 'Senha',
  log_in: 'Entrar',
  forgot_your_password: 'Esqueceu a sua senha?',
  recover_here: 'Recupere aqui',
  model: 'Modelo',
  models: 'Modelos',
  allModels: 'Todos os modelos',
  provider: 'Adquirente',
  providers: 'Adquirentes',
  allProviders: 'Todos os adquirentes',
  devices: 'Dispositivos',
  deviceCount: "{{count}} dispositivo",
  deviceCount_plural: "{{count}} dispositivos",
  serialNumber: 'Número de série',
  dcName: 'Nome do CD',
  distributionCenter: 'Centro de distribuição',
  distributionCenters: 'Centros de distribuição',
  allDistributionCenters: 'Todos os centros de distribuição',
  all: 'Todos',
  search: 'Buscar',
  add: 'Incluir',
  dc: 'CD',
  save: 'Salvar',
  addDevice: 'Incluir dispositivo',
  results: 'Resultados',
  cancel: 'Cancelar',
  select: 'Selecione',
  devicesSaved: 'Dispositivos salvos com sucesso',
  distributionCenterSaved: 'Centro de distribuição salvo com sucesso',
  distributionCenterDeleted: 'Centro de distribuição excluído com sucesso',
  noDistributionCenterAdded: 'Nenhum centro de distribuição adicionado',
  noResultFound: 'Nenhum resultado encontrado',
  fieldRequired: 'Campo obrigatório',
  edit: 'Editar',
  transfer: 'Transferir',
  origin: 'Origem',
  destination: 'Destino',
  transferDevice: 'Transferir dispositivo',
  close: 'Fechar',
  deviceTransfered: 'Dispositivo(s) transferido(s) com sucesso',
  event: 'Evento',
  site: 'Local',
  tenant_: 'Cliente:',
  loading: 'Carregando',
  exit: 'Sair',
  invalidLogin: 'Não foi possível efetuar login, usuário ou senha inválidos.',
  devicesCount: "{{count}} dispositivo",
  devicesCount_plural: "{{count}} dispositivos",
  devicesGroupSaved: 'Grupo de dispositivo salvo com sucesso',
  devicesGroupDeleted: 'Grupo de dispositivo excluído com sucesso',
  noDevicesGroupAdded: 'Nenhum grupo de dispositivo adicionado',
  deviceGroupName: 'Nome do grupo de dispositivo',
  devicesGroups: 'Grupos de dispositivos',
  devicesGroup: 'Grupo de dispositivos',
  group: 'Grupo',
  requestFailure:
    'Não foi possível processar a sua requisição no momento. Tente novamente mais tarde.',
  deviceStatus: 'Status do dispositivo',
  deviceStatusName: 'Nome do status',
  noDeviceStatusAdded: 'Nenhum status do dispositivo adicionado',
  deviceStatusSaved: 'Status do dispositivo salvo com sucesso',
  deviceStatusDeleted: 'Status do dispositivo excluído com sucesso',
  status: "Status",
  note: 'Observação',
  allocationType: "Tipo de Alocação",
  tenantAssociationSelectLabel: "Digite o nome ou Tenant ID de um cliente",
  pointAssociationSelectLabel: "Digite o nome ou ID de uma operação",
  points: "Operações",
  tenantAlreadyAssociated: "Cliente filho já associado",
  associatedPointsWithChildTenant: 'Operações utilizando as licenças deste cliente filho',
  associatedPointsWithChildTenantDescription: "Gerencie as operações que estão associadas a este cliente filho, as operações associadas utilizarão as licenças do contrato do cliente filho abaixo:",
  childTenants: 'Clientes filhos',
  childTenantsDescription: 'Selecione abaixo quais clientes serão filhos deste cliente. Os clientes filhos poderão ser associados as operações deste cliente. As operações utilizarão as licenças dos contratos do cliente filho na qual foram associadas.',
};
